import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import PinkCard from '../components/PinkCard'
import EmailListForm from '../components/EmailListForm'

import './tags.css'

class TagRoute extends React.Component {
  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }
  render() {
    const posts = this.props.data.posts.edges
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const tags = this.props.data.tags.edges

    let currentColor, currentTag;
    if (tag === 'fashion') {
      currentColor = 'green'
      currentTag = tags.find((tag) => {
        if(tag.node.frontmatter.title === 'Fashion') {
          return tag
        } else return null
      })
    } else if (tag === 'prints') {
      currentColor = 'purple'
      currentTag = tags.find((tag) => {
        if(tag.node.frontmatter.title === 'Prints') {
          return tag
        } else return null
      })
    } else if (tag === 'treats') {
      currentColor = 'pink'
      currentTag = tags.find((tag) => {
        if(tag.node.frontmatter.title === 'Treats') {
          return tag
        } else return null
      })
    }

    console.log(posts)

    return (
      <Layout color={currentColor}>
        <section className="section">
          <Helmet title={`${tag} | ${title}`} />
          <div className="container content">
            {currentTag && 
              <PinkCard>
                <div className='card-text'>
                  <h1 className='intro-title' style={{color: '#FFF', fontWeight: 'lighter'}}>{currentTag.node.frontmatter.foldTitle}</h1>
                  <p style={{fontSize: '1.2rem'}}>{currentTag.node.frontmatter.foldBody}</p>
                </div>
                <div className={`card-img card-img-${tag}`} style={{ background: `url(${currentTag.node.frontmatter.foldImg})`, backgroundSize: 'cover', backgroundPosition: '50%, 50%'}}  />
              </PinkCard>
            }
            <div className="tag-item-wrapper">
              {posts.map(post => (
                <Link className={`tag-item tag-item-${tag}`} key={post.node.fields.slug} to={post.node.fields.slug}>
                  <div className='tag-img' style={{background: `url(${post.node.frontmatter.image})`, backgroundSize: 'cover', backgroundPosition: '50%, 50%'}} />
                  <div className='tag-img-label'>
                    <h2>{post.node.frontmatter.title}</h2>
                  </div>
                </Link>
              ))}
            </div>
            <PinkCard>
              <div style={{width: '100%', textAlign: 'center'}}>
                <h1 className='intro-title' style={{marginBottom: 9, color: '#FFF', fontWeight: 'lighter'}}>Stay Inspired!</h1>
                <p style={{fontSize: '1.2rem', marginBottom: 24}}>Sign up to get notified when I publish a new post and get 20% off your first order!</p>
                <EmailListForm />
              </div>
            </PinkCard>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            image 
          }
        }
      }
    }
    tags: allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "tags" }}}
    ) {
      edges {
        node {
          frontmatter {
            templateKey
            title
            metaTitle
            metaDesc
            metaImg
            foldTitle
            foldBody
            foldImg 
          }
        }
      }
    }
  }
`
